import { RoofTile } from './rooftile';
import { TileFamily, TileFinish, TileColor, RoofArticle, Material, Underlagstak, MarketStr, isCompatMode } from '@/calculation/common';
import { ExtraAssortment, AccessoryColor } from '@/enums';
import { replaceByArtnr } from '@/helpers/tile';

import { i18n } from '@/i18n';
const { t } = i18n.global;

export class HansaTile extends RoofTile {
  public constructor() {
    // TODO: Amount per pallet varies depending on selected color!
    super(TileFamily.HansaFalsatLertegel, 264, -1);
    this.label = 'Hansa 1-kupig';
    this.key = 'hansa';
    this.material = Material.Lertegel;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 320,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
      [MarketStr.Norge]: {
        min: 320,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
      [MarketStr.Tyskland]: {
        min: 320,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: 340,
      [MarketStr.Norge]: 365,
      [MarketStr.Tyskland]: 340,
    };

    this.itemNos = {
      [RoofArticle.TakPanna]: 'H10024',
      [RoofArticle.NockPanna]: 'H30024',
      [RoofArticle.NockBörjan]: 'H31524',
      [RoofArticle.NockSlut]: 'H31624',
      [RoofArticle.NockBörjanGavel]: 'H31824',
      [RoofArticle.NockSlutGavel]: 'H32024',
      [RoofArticle.BörjanValm]: 'H31024',
      [RoofArticle.BörjanValmAlt]: 'H32124',
      [RoofArticle.ValmKlocka]: 'H35024',
      [RoofArticle.NockPannaAlt]: 'H30424',
      [RoofArticle.NockBörjanAlt]: 'H32124',
      [RoofArticle.NockSlutAlt]: 'H32224',
      [RoofArticle.ValmKlockaAlt]: 'H35224',
      [RoofArticle.NockAnslutning]: 'H10324',
      [RoofArticle.GavelPannaNAVänster]: 'H40324',
      [RoofArticle.GavelPannaNAHöger]: 'H40224',
      [RoofArticle.GavelPannaVänsterBred]: 'H40124',
      [RoofArticle.GavelPannaHögerBred]: 'H40024',
      [RoofArticle.Ventilationshuv]: 'H52124',
      [RoofArticle.Ventilationshuv2]: 'H88624',
      [RoofArticle.VentilationshuvMontering]: '060200',
      [RoofArticle.Avluftare]: 'H50524',
      [RoofArticle.Avluftare2]: 'H50524',
      [RoofArticle.AvluftarePlåt]: 'H51124',
      [RoofArticle.AvluftarePlåtMontering]: '060200',
      [RoofArticle.AvluftareLertegel]: 'H50524',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: 'H83124',
      // [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Snöglidhinder]: '081720',
      [RoofArticle.SnöglidhinderKonsol]: '081820',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Rändalstätning]: '118520',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.SidofallsKlammer]: 'H96491',
      [RoofArticle.DiagonalClip]: 'L96290',
      [RoofArticle.DiagonalClipZial]: 'L96190',
      // [RoofArticle.Stormclips2]: '096691',
      [RoofArticle.EasyClip]: '096791',
      [RoofArticle.EasyClip38]: '096691',
      [RoofArticle.EasyClip50]: '096791',
      [RoofArticle.EasyClip45x70]: '067191',
      [RoofArticle.EasyClip30x48]: '067791',
      [RoofArticle.EasyClip36x48]: '068291',
      [RoofArticle.Nockskruv]: '094391',
      [RoofArticle.Nockklammer]: 'H114724',
      [RoofArticle.NockklammerAlt]: 'H124724',
      [RoofArticle.NockKleeblatt]: 'H124720',
      [RoofArticle.Nockbrädshållare]: '065190',

      // Roof Secutity
      [RoofArticle.Bärläktsteg45]: 'H72320',
      [RoofArticle.Bärläktsteg25]: 'H72120',
      [RoofArticle.KoppladeTaksteg]: 'H84120',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: '083820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SpårPanna]: 'H73524',
      [RoofArticle.SpårPannaBörjan]: 'H73424',
      [RoofArticle.SpårPannaSlut]: 'H73624',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '081890',
      [RoofArticle.IsStoppare]: '083020',

      [RoofArticle.BTSUnderlagstak]: '128001',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Ventilationshuv]: 'H88624',
        [RoofArticle.Valmtätning300]: '092420',
      },
    };
  }

  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    if (market === MarketStr.Tyskland) return { valid: false, message: t('validation.notice.disabled_due_to_market') };
    // 320 <= Läktavstånd <= 345
    if (lAvstånd && (lAvstånd < 320 || lAvstånd > 345)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 14, max: 80 }) || !this.angleInRange(taklutning.max, { min: 14, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number, underlagstak: Underlagstak): number {
    const ang = Number(angle.toFixed(1));
    if (underlagstak == Underlagstak.RåspontMedPapp || underlagstak == Underlagstak.LättMedStröläkt) {
      if (ang >= 14 && ang <= 22.5) return 55;
      if (ang >= 22.6 && ang <= 27.5) return 50;
      if (ang >= 27.6 && ang <= 32.5) return 45;
      if (ang >= 32.6 && ang <= 37.5) return 40;
      if (ang >= 37.6 && ang <= 42.5) return 35;
      if (ang >= 42.6 && ang <= 47.5) return 30;
      if (ang >= 47.6 && ang <= 52.5) return 25;
      if (ang >= 52.6 && ang <= 57.5) return 20;
      return 20;
    } else {
      if (ang >= 14 && ang <= 22.5) return 50;
      if (ang >= 22.6 && ang <= 27.5) return 45;
      if (ang >= 27.6 && ang <= 32.5) return 40;
      if (ang >= 32.6 && ang <= 37.5) return 35;
      if (ang >= 37.6 && ang <= 42.5) return 30;
      if (ang >= 42.6 && ang <= 47.5) return 25;
      if (ang >= 47.6 && ang <= 52.5) return 20;
      if (ang >= 52.6 && ang <= 57.5) return 15;
      return 15;
    }
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
          case AccessoryColor.Black:
            items.push(...['H52120', 'H51120']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['H52124', 'H51124']);
            break;
        }
        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}

export class HansaRakTile extends RoofTile {
  public constructor() {
    // TODO: Amount per pallet varies depending on selected color!
    super(TileFamily.HansaFalsatLertegelRakFramkant, 264, -1, 'H101');
    this.label = 'Hansa rak framkant 1-kupig';
    this.key = 'hansa_rak';
    this.material = Material.Lertegel;
    this.läktavstånd = {
      [MarketStr.Sverige]: {
        min: 320,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
      [MarketStr.Norge]: {
        min: 320,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
      [MarketStr.Tyskland]: {
        min: 320,
        angle14to18: 345,
        angle18to22: 345,
        angle22to80: 345,
      },
    };
    this.avstTillFörstaLäkt = {
      [MarketStr.Sverige]: isCompatMode() ? 320 : 340,
      [MarketStr.Norge]: 365,
      [MarketStr.Tyskland]: isCompatMode() ? 320 : 340,
    };

    this.itemNos = {
      // TODO: Update to new calc
      [RoofArticle.TakPanna]: 'H10124',
      [RoofArticle.NockPanna]: 'H30024',
      [RoofArticle.NockBörjan]: 'H31524',
      [RoofArticle.NockSlut]: 'H31624',
      [RoofArticle.NockBörjanGavel]: 'H31824',
      [RoofArticle.NockSlutGavel]: 'H32024',
      [RoofArticle.BörjanValm]: 'H31024',
      [RoofArticle.BörjanValmAlt]: 'H32124',
      [RoofArticle.ValmKlocka]: 'H35024',
      [RoofArticle.ValmKlockaAlt]: 'H35024', // This tile doesn't have an alt-tile (it seems?) but to make roof.ts cleaner write its valmklocka as alt too
      [RoofArticle.NockAnslutning]: 'H10424',
      [RoofArticle.Ventilationshuv]: 'H52124',
      [RoofArticle.Ventilationshuv2]: 'H88624',
      [RoofArticle.VentilationshuvMontering]: '060200',
      [RoofArticle.Avluftare]: 'H50624',
      [RoofArticle.Avluftare2]: 'H50624',
      [RoofArticle.AvluftarePlåt]: 'H51124',
      [RoofArticle.AvluftarePlåtMontering]: '060200',
      [RoofArticle.AvluftareLertegel]: 'H50624',
      [RoofArticle.Rififi]: '060000',
      [RoofArticle.InfästTaksteg]: '081190',
      [RoofArticle.Taksteg]: 'H83124',
      // [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Snöglidhinder]: '081720',
      [RoofArticle.SnöglidhinderKonsol]: '081820',
      [RoofArticle.Valmtätning300]: '091620',
      [RoofArticle.Valmtätning370]: '090720',
      [RoofArticle.Rändalstätning]: '118520',
      [RoofArticle.Fågelband]: '092020',
      [RoofArticle.Fågelband5mSvart]: '092120',
      [RoofArticle.Fågelband5mRöd]: '092124',
      [RoofArticle.VentiFågelband]: '091520',
      [RoofArticle.Takluftare]: '097200',
      [RoofArticle.Nocktätningsremsa]: '093110',
      [RoofArticle.SidofallsKlammer]: 'H96491',
      [RoofArticle.DiagonalClip]: 'L96290',
      [RoofArticle.DiagonalClipZial]: 'L96190',
      // [RoofArticle.Stormclips2]: '096691',
      [RoofArticle.EasyClip]: '096791',
      [RoofArticle.EasyClip38]: '096691',
      [RoofArticle.EasyClip50]: '096791',
      [RoofArticle.EasyClip45x70]: '067191',
      [RoofArticle.EasyClip30x48]: '067791',
      [RoofArticle.EasyClip36x48]: '068291',
      [RoofArticle.Nockskruv]: '094391',
      [RoofArticle.Nockklammer]: 'H114724',
      [RoofArticle.NockKleeblatt]: 'H124720',
      [RoofArticle.Nockbrädshållare]: '065190',

      // Roof Secutity
      [RoofArticle.Bärläktsteg45]: 'H72320',
      [RoofArticle.Bärläktsteg25]: 'H72120',
      [RoofArticle.KoppladeTaksteg]: 'H84120',
      [RoofArticle.InfästningKoppladeTaksteg]: '083990',
      [RoofArticle.Glidskydd]: '072920',
      [RoofArticle.Taklucka]: '083820',
      [RoofArticle.Säkerhetskrok]: '070120',
      [RoofArticle.Snökrok]: '081420',
      [RoofArticle.Fästögla]: '171690',
      [RoofArticle.SpårPanna]: 'H73524',
      [RoofArticle.SpårPannaBörjan]: 'H73424',
      [RoofArticle.SpårPannaSlut]: 'H73624',
      [RoofArticle.SnowSlideObstacle]: '081790',
      [RoofArticle.SnowSlideObstacleKonsol]: '081890',
      [RoofArticle.IsStoppare]: '083020',

      [RoofArticle.BTSUnderlagstak]: '128001',
    };

    this.marketSpecificItemNos = {
      [MarketStr.Norge]: {
        [RoofArticle.Ventilationshuv]: 'H88624',
        [RoofArticle.Valmtätning300]: '092420',
      },
    };
  }

  public isSelectable(
    lAvstånd?: number | null,
    taklutning?: { min: number; max: number } | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    underlagstak?: Underlagstak | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    market?: MarketStr | null,
  ): { valid: boolean; message: string } {
    if (market === MarketStr.Tyskland) return { valid: false, message: t('validation.notice.disabled_due_to_market') };
    // 320 <= Läktavstånd <= 345
    if (lAvstånd && (lAvstånd < 320 || lAvstånd > 345)) return { valid: false, message: t('validation.notice.disabled_due_to_lakt') };
    if (taklutning && (!this.angleInRange(taklutning.min, { min: 14, max: 80 }) || !this.angleInRange(taklutning.max, { min: 14, max: 80 })))
      return { valid: false, message: t('validation.notice.disabled_due_to_angle') };

    return { valid: true, message: '' };
  }

  public bärläktAvståndNock(angle: number, underlagstak: Underlagstak): number {
    const ang = Number(angle.toFixed(1));
    if (underlagstak == Underlagstak.RåspontMedPapp || underlagstak == Underlagstak.LättMedStröläkt) {
      if (ang >= 14 && ang <= 22.5) return 55;
      if (ang >= 22.6 && ang <= 27.5) return 50;
      if (ang >= 27.6 && ang <= 32.5) return 45;
      if (ang >= 32.6 && ang <= 37.5) return 40;
      if (ang >= 37.6 && ang <= 42.5) return 35;
      if (ang >= 42.6 && ang <= 47.5) return 30;
      if (ang >= 47.6 && ang <= 52.5) return 25;
      if (ang >= 52.6 && ang <= 57.5) return 20;
      return 20;
    } else {
      if (ang >= 14 && ang <= 22.5) return 50;
      if (ang >= 22.6 && ang <= 27.5) return 45;
      if (ang >= 27.6 && ang <= 32.5) return 40;
      if (ang >= 32.6 && ang <= 37.5) return 35;
      if (ang >= 37.6 && ang <= 42.5) return 30;
      if (ang >= 42.6 && ang <= 47.5) return 25;
      if (ang >= 47.6 && ang <= 52.5) return 20;
      if (ang >= 52.6 && ang <= 57.5) return 15;
      return 15;
    }
  }

  public getAccessoryOptions(
    assortment: ExtraAssortment,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { color, finish, accessoryColor }: { color: TileColor; finish: TileFinish; accessoryColor: AccessoryColor },
  ) {
    const items: string[] = [];
    switch (assortment) {
      case ExtraAssortment.VentilationGenomforing:
        switch (accessoryColor) {
          case AccessoryColor.GalvanizedGreySilver:
          case AccessoryColor.Black:
            items.push(...['H52120', 'H51120']);
            break;
          case AccessoryColor.BrickRed:
            items.push(...['H52124', 'H51124']);
            break;
        }
        break;
    }

    // Replace artnr if necessary
    for (let i = 0; i < items.length; i++) {
      items[i] = replaceByArtnr(items[i]);
    }

    return items;
  }
}
